body, html {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #FDD835;
  height: 100%;
  overflow: hidden;
}
h1 {
  text-align: center;
  position: relative;
  top: 25%;
  transform: translateY(-50%);
  font-size: 10vh;
  font-family: 'Montserrat', sans-serif;
  user-select: none;
  color: #404040;
}